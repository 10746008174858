import { Main } from 'ui/components/4-habitats/Main/Main';
import { ContentSection, ContentSectionProps } from 'ui/components/4-habitats/ContentSection/ContentSection';
import { Footer, FooterProps } from 'ui/components/4-habitats/Footer/Footer';
import { HeaderProps } from 'ui/components/4-habitats/Header/Header';
import { TileCard } from 'ui/components/2-molecules/Card/TileCard/TileCard';

export interface ErrorPageProps {
	errorCode: number;
	message?: string;
	contentSection: ContentSectionProps;
	header: HeaderProps;
	footer: FooterProps;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ header, footer, errorCode, message, contentSection }) => {
	return (
		<>
			<Main>
				<ContentSection {...contentSection}>
					<TileCard
						heading={`${errorCode.toString()}`}
						text="We can not find the page you are looking for. Maybe it has been moved, deleted or the link to the page is incorrect."
						kicker={{ firstWord: `${message} ` }}
					/>
				</ContentSection>
			</Main>
			<Footer {...footer} />
		</>
	);
};
