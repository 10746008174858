import logger from 'helpers/logger';
import { queryCmsContent } from './_api';

export const getSiteFromCms = async (preview: boolean, previewData: Content.PreviewData): Promise<Site.Site> => {
	const query = `{
		site: page(path: "/")
		{
			culture
			properties
		}
	}`;

	const profiler = logger.startTimer();
	try {
		const data = await queryCmsContent({
			query,
			method: 'POST',
			preview,
			previewData,
		});

		return {
			culture: data.site?.culture ?? null,
			title: data.site?.properties?.siteTitle ?? null,
			settings: {
				tagManager: data.site.properties.siteSettings?.find((e) => e.documentType === 'googleTagManager')?.content?.properties || null,
				maps: data.site.properties.siteSettings?.find((e) => e.documentType === 'mapsSettings')?.content?.properties || null,
				globalTheme: data.site.properties.siteSettings?.find((e) => e.documentType === 'globalTheme')?.content?.properties || null,
			},
		};
	} finally {
		profiler.done({ message: 'getSite', level: 'debug' });
	}
};
