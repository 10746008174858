import logger from 'helpers/logger';
import { PageTypeFeature } from 'features/Modules/PageType/PageTypeFeature';
import { LayoutFeature } from 'features/Layouts/Layout/LayoutFeature';
import type { NextPage } from 'next';
import { PageProps } from 'domain/page/pageProps';

const Error404: NextPage<PageProps> = (pageProps: PageProps) => {
	logger.info('Rendering 404 with SSG/SSR');

	const { content } = pageProps ?? {};
	const { page } = content ?? {};

	return (
		<LayoutFeature {...pageProps}>
			<PageTypeFeature {...page} />
		</LayoutFeature>
	);
};

export default Error404;
