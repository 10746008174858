import logger from 'helpers/logger';
import { queryCmsContent } from './_api';

export const getPageContentFromCms = async (path: string, preview: boolean, previewData: Content.PreviewData): Promise<Content.PageContent> => {
	const query = `{
		root: page(path: "/")
		{
			properties(fields: ["siteSettings"]) 
		}
		page: page {
			id
			name
			documentType
			url
			properties
			template
			culture
		}
		siteTheme: pageSettings(settings: [{documentTypeAlias: "siteTheme", inherit: true}]) {
			documentType
            content {
                documentType
                properties
            }
            settings {
                documentType
                properties
            }
		}
		footer: field(path:"/${path}", field: "footer") 
	}`;

	const profiler = logger.startTimer();

	try {
		const data = await queryCmsContent({
			query,
			method: 'POST',
			preview,
			previewData,
			path,
		});
		const retVal: Content.PageContent = {
			...data,
			footer: {
				...data.footer?.footer[0].content?.properties,
			},
		};
		return retVal;
	} finally {
		profiler.done({ message: 'getPageContent', level: 'debug' });
	}
};
