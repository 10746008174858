import { getSiteFromCms } from 'application/adapters/cms/siteAdapter';

export interface GetSiteProps {
	preview?: boolean;
	previewData?: Content.PreviewData;
}
export const getSite = async ({ preview, previewData }: GetSiteProps): Promise<Site.Site> => {
	const site = await getSiteFromCms(preview, previewData);
	if (!site.settings) {
		site.settings = {};
	}

	return site;
};
