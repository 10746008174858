import Error404 from 'features/Error/404';
import ErrorGeneric from 'features/Error/Generic';
import Application from 'application/entities/Application';
import { getDictionaryItems } from 'application/repositories/dictionaryRepository';
import { getNavigationData } from 'application/repositories/navigationRepository';
import { getPageContent } from 'application/repositories/pageContentRepository';
import { getSite } from 'application/repositories/siteRepository';
import logger from 'helpers/logger';
import absoluteUrl from 'next-absolute-url';
import { ApiRedirect } from 'helpers/apiRedirect';
import { PageProps } from 'domain/page/pageProps';

function Error(pageProps: PageProps & { statusCode: number; statusMessage: string; error: unknown | string | null }): JSX.Element {
	const { statusCode, statusMessage, error } = pageProps;

	return (
		<>
			{/* <p style={{background: 'yellow'}}>{statusCode ? `An error ${statusCode} occurred on server` : 'An error occurred on client'}</p> */}

			{statusCode === 404 ? <Error404 {...pageProps} /> : <ErrorGeneric {...{ statusCode }} {...{ statusMessage }} {...{ error }} />}
		</>
	);
}

Error.getInitialProps = async (context) => {
	const { req, res, err, locale } = context;

	const { origin } = absoluteUrl(req);
	Application.setUrl(origin as string);
	locale ? Application.setLocale(locale) : null;
	// The Application Entity contains functions as well as data so
	// to clean it and make it serializable we stringify and parse it
	const serializedApplication = JSON.parse(JSON.stringify(Application));
	if (locale) {
		serializedApplication.locale = Application.locale.toString();
	}

	// TODO: Find out how to set the correct protocol for subdomains.
	// When using fx. english.localhost:3001 next-absolute-url reports
	// https instead of http.
	// So for now we grab the protocol from the request hostname.

	try {
		if (req.headers.hostname === undefined) {
			logger.warn('_Error.tsx page was called without a hostname header', req.headers);
		} else {
			const { protocol } = new URL(req.headers.hostname);
			Application.url.protocol = protocol;
		}
	} catch (e) {
		logger.error('_Error.tsx page was called without a valid hostname header', req.headers, { e });
		throw Error(e);
	}

	const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
	const statusMessage = res ? res.statusMessage : err ? err.message : null;

	if (err instanceof ApiRedirect) {
		res.writeHead(err?.statusCode, {
			'Cache-Control': 'no-cache, no-store, must-revalidate',
			Expires: '0',
			Pragma: 'no-cache',
			Location: err.url || '',
		});
		res.end();
	}

	if (statusCode === 404) {
		const path = '404';

		// Just for testing content without language variants
		// let path = '404';
		// const locale = Application.locale?.baseName;
		// if (locale) {
		// 	path = `${locale}/${path}`;
		// }

		try {
			const dictionaryPromise = getDictionaryItems({});
			const navigationPromise = getNavigationData({});
			const sitePromise = getSite({});
			const contentPromise = getPageContent({
				path,
			});

			return {
				statusCode,
				statusMessage,
				error: err,
				content: await contentPromise,
				navigation: await navigationPromise,
				dictionary: await dictionaryPromise,
				site: await sitePromise,
				// The Application Entity contains functions as well as data so
				// to clean it and make it serializable we stringify and parse it
				application: serializedApplication,
			};
		} catch (error) {
			logger.error(`getInitialProps() \nCannot get content for 404 error page \n${error}`);
			throw error;
		}
	} else {
		return {
			statusCode,
			statusMessage,
			error: err,
		};
	}
};

export default Error;
