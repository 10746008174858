import { getPageContentFromCms } from 'application/adapters/cms/pageContentAdapter';
import { axiosInstance as axios } from 'helpers/axiosInstance';
import { ApiError } from 'helpers/apiError';
import logger from 'helpers/logger';

export interface GetPageContentProps {
	path: string;
	preview?: boolean;
	previewData?: Content.PreviewData;
}

export const getPageContent = async ({ path, preview, previewData }: GetPageContentProps): Promise<Content.PageContent> => {
	return getPageContentFromCms(path, preview, previewData);
};

export const get404Content = async (): Promise<Content.PageContent> => {
	try {
		const res = await axios('/api/get404content');
		return res.data;
	} catch (err) {
		const errorCode = err?.response?.status ?? 500;
		const errorMessage = err?.message ?? 'Unknown error';

		logger.error(`[${errorCode}] Generic Request \nRequest to **/404 threw an error: \n${errorMessage}`);
		throw new ApiError('Generic Request', errorCode, `\nRequest to **/404 threw an error: \n${errorMessage}`);
	}
};
