import { NextPage } from 'next';
import logger from 'helpers/logger';
import { ErrorPage } from 'ui/components/6-pages/ErrorPage/ErrorPage';

const ErrorGeneric: NextPage<PageProps> = (pageProps) => {
	const { statusCode, statusMessage } = pageProps;
	logger.info('Rendering Generic Error with SSG/SSR');

	return <ErrorPage header={null} footer={null} errorCode={statusCode} message={statusMessage} contentSection={null} />;
};

type PageProps = {
	statusCode?: number;
	statusMessage?: string;
	error?: unknown;
};

export default ErrorGeneric;
